/* eslint-disable import/no-default-export */

import * as React from 'react'
import { useState, useRef } from 'react'

import './image.css'
import { graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import Lightbox from 'react-image-lightbox'

import 'react-image-lightbox/style.css'
import type { Image } from './components/blog-post'
import type { IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image'

import { Layout } from '../components/Layout/Layout'
import { ScrollButtons } from '../components/elements/work/ScrollButtons'
import {
  CopySection,
  ImageSection,
  MobileImageSection,
  VideoSection,
} from './components/blog-post'

const BlogPostTemplate = ({
  data,
  location,
}: {
  data: any
  location: any
}): JSX.Element => {
  const post = data.markdownRemark
  const images: Image[] = post.frontmatter.images
  const video: string = post.frontmatter.video
  const tags: string[] = post.frontmatter.tags || []
  const description: string = post.frontmatter.description || ''
  const keywords: string = post.frontmatter.keywords || ''

  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const imageSources = images?.map(
    img => img?.childImageSharp?.gatsbyImageData?.images?.fallback?.src || ''
  )

  const ref = useRef(null)

  const handleLeftClick = () => {
    const element = ref.current as unknown as HTMLDivElement
    element.scrollBy({
      left: -element.clientWidth / 2,
      behavior: 'smooth',
    })
  }

  const handleRightClick = () => {
    const element = ref.current as unknown as HTMLDivElement
    element.scrollBy({
      left: element.clientWidth / 2, //200,
      behavior: 'smooth',
    })
  }

  return (
    <Layout
      location={location}
      title={post.frontmatter.title}
      description={description || post.excerpt}
      keywords={keywords}
      isProject={true}
      alternate={post.frontmatter.alternate || ''}
      lang={post.frontmatter.lang}
    >
      {images && !video && (
        <ImageSection ref={ref} isSingle={images.length === 1}>
          <span style={{ minWidth: '-16px' }} />
          {(images as ImageDataLike[])?.map(
            (image: ImageDataLike, index: number) => {
              const imageFile = getImage(image) as IGatsbyImageData
              const isVertical = imageFile.height > imageFile.width
              const isSingle = images.length === 1
              const tag = tags[index] || ''
              return (
                <GatsbyImage
                  className={
                    isVertical
                      ? `vertical-image click-image work ${
                          isSingle ? 'single' : ''
                        }`
                      : `styled-image click-image work ${
                          isSingle ? 'single' : ''
                        }`
                  }
                  image={imageFile}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  alt={tag}
                  objectFit="contain"
                  loading="eager"
                  onClick={() => {
                    setPhotoIndex(index)
                    setIsOpen(true)
                  }}
                />
              )
            }
          )}
          <span style={{ minWidth: '12px' }} />
        </ImageSection>
      )}

      {images?.length > 1 && (
        <ScrollButtons
          leftClick={handleLeftClick}
          rightClick={handleRightClick}
        />
      )}
      {images && !video && (
        <MobileImageSection>
          {(images as ImageDataLike[])?.map(
            (image: ImageDataLike, index: number) => {
              const imageFile = getImage(image) as IGatsbyImageData
              const isVertical = imageFile.height > imageFile.width
              const tag = tags[index] || ''
              return (
                <GatsbyImage
                  className={
                    isVertical
                      ? 'vertical-image work mobile'
                      : 'styled-image work mobile'
                  }
                  image={imageFile}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  alt={tag}
                  objectFit="contain"
                  loading="eager"
                />
              )
            }
          )}
        </MobileImageSection>
      )}
      {video && (
        <VideoSection>
          <iframe
            src={video}
            frameBorder="0"
            id="videoFrame"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </VideoSection>
      )}
      <CopySection
        dangerouslySetInnerHTML={{ __html: post.html }}
        itemProp="articleBody"
      />

      {isOpen && imageSources.length && (
        <Lightbox
          mainSrc={imageSources[photoIndex]}
          nextSrc={imageSources[(photoIndex + 1) % imageSources.length]}
          prevSrc={
            imageSources[
              (photoIndex + imageSources.length - 1) % imageSources.length
            ]
          }
          imageCaption={`${photoIndex + 1}/${imageSources.length}`}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + imageSources.length - 1) % imageSources.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % imageSources.length)
          }
          enableZoom={false}
          discourageDownloads={true}
          animationDisabled={true}
        />
      )}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        slug
      }
      frontmatter {
        description
        keywords
        video
        tags
        images {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              formats: [AUTO, JPG]
              quality: 100
            )
          }
        }
        alternate
        lang
        title
        order
      }
      html
    }
  }
`
