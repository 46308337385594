import * as React from 'react'

import styled from '@emotion/styled'

import { ChevronLeft, ChevronRight } from '../iconography/Chevrons'

const ScrollContainer = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  @media (max-width: 767px) {
    display: none;
  }
`

const ScrollButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 32px;
  padding: 2px;
  margin-right: 0;
  cursor: pointer;
  background: #fff;
  //padding: 0;
`

export const ScrollButtons = ({
  leftClick,
  rightClick,
}: {
  leftClick: () => void
  rightClick: () => void
}): JSX.Element => (
  <ScrollContainer>
    <ScrollButton
      onClick={() => {
        leftClick()
      }}
    >
      <ChevronLeft size="14px" />
    </ScrollButton>
    <ScrollButton
      onClick={() => {
        rightClick()
      }}
    >
      <ChevronRight size="14px" />
    </ScrollButton>
  </ScrollContainer>
)
