import styled from '@emotion/styled'

export type GatsbyImageData = {
  layout: string
  placeholder: { fallback: string }
  backgroundColor: string
  images: {
    fallback: {
      sizes: string
      src: string
      srcSet: string
    }
  }
  width: number
  height: number
}

export type ChildImageSharp = {
  gatsbyImageData: GatsbyImageData
}

export type Image = {
  childImageSharp: ChildImageSharp
}

export const ImageSection = styled.section<{ isSingle: boolean }>`
  display: flex;
  flex-direction: row nowrap;
  flex-shrink: 0;
  overflow-x: auto;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-wrap: nowrap;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  @media (max-width: 767px) {
    display: none;
  }
  @media (min-width: 1025px) {
    max-height: 60vh;
  }
  ${props =>
    props.isSingle
      ? `
        /*max-height: 43vh;*/
        max-height: 60vh;
        align-items: center;
        justify-content: center;
        @media (min-width: 1025px) {
          max-height: 60vh;
        }`
      : ''};
`

export const MobileImageSection = styled.section`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  @media (min-width: 768px) {
    display: none;
  }
`
export const VideoSection = styled.section`
  display: flex;
  flex-direction: row nowrap;
  flex-shrink: 0;
  overflow-x: auto;
  flex-grow: 1;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-wrap: nowrap;
  min-height: 56.25%;

  /* min-height: 34vh;
  max-height: 43vh; */
  /*min-height: 60vh;*/

  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  /*   @media (min-width: 1025px) {
 
  } */
  iframe {
    width: 100%;
    height: auto;
    min-width: 100%;
    min-height: 34vw;
  }
`

export const CopySection = styled.section`
  padding-top: 1rem;
  width: 100%;
  @media (min-width: 1024px) {
    width: 75%;
  }

  h2 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }

  .post_main {
    margin-top: 2.5rem;
    & + p {
      margin-top: 1rem;
    }
  }
  p.last_post {
    margin-bottom: 2rem;
  }
`
